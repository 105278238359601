import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
  fontSize: "36px",
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Karaoke</title>
      <h1>KJ Scallywag</h1>
      <div>
        Please use the below link to access the Karaoke Songbook and request
        songs. Please only submit one song per singer rotation.
      </div>
      <br />
      <br />
      <a target="_blank" href={`//karafun.com/${process.env.GATSBY_KARAFUN}`}>
        Click Me
      </a>
    </main>
  );
};

export default IndexPage;
